import React from 'react';

import { useAuthenticator } from "@aws-amplify/ui-react";

import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Button } from "primereact/button";

import styles from "./Header.module.css";
import logo from "../../assets/logo.svg";

/**
 * Main header component, prop determines whether the menu is showing.
 */
export const Header = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    const showBurgerMenu = () => {
        // We normally see: "configuring", then either "unauthenticated" or "authenticated"
        console.log(authStatus);

        return (
            authStatus == "authenticated" ?
                <div className={`${styles.flexChild}`}>
                    <Avatar className="p-overlay-badge" icon="pi pi-user" size="xlarge">
                    <Badge value="4" />
                    </Avatar>
                </div>
            :
                <div className={`${styles.flexChild}`}>
                    <button className="login-button">Login</button>
                    <button className="get-started-button">Get Started for free</button>
                </div>
        );
    };

    const headerLogo = () => {
        return logo;
    };

    return (
        <header className={`bg-white w-full header ${styles.header}`} data-cy="header">
            <div className={`${styles.flexChild} ${styles.logoContainer}`}>
                <img className={styles.Logo} src={headerLogo()} />
            </div>
            <div className={`${styles.flexChild} ${styles.menuContainer}`}>
            </div>
            {showBurgerMenu()}
        </header>
    );
};