import { ReactElement } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AUTH_TIMEOUT, AUTH_URL_PROTOCOL } from "../../config/auth";


interface Props {
    children: ReactElement
}

export function SecuredRoute(props:Props): ReactElement {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const location = useLocation();

    // We have hit a roadblock where this timeout is required, hopefully we can find another way...
    useEffect(() => {
        const timer = setTimeout(() => {
            if (authStatus !== "authenticated") {
                redirectToSSO();
            }
        }, AUTH_TIMEOUT);
        return () => clearTimeout(timer);
    }, [authStatus]);

    const redirectToSSO = () => {
        window.location.assign(
            `${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`
        );
    };

    return authStatus !== "authenticated" ? (
        <div className="flex h-screen justify-items-center align-items-center">
            <ProgressSpinner />
        </div>
    ) : (
        props.children
    );
}
