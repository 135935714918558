import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { I18nextProvider } from "react-i18next";
import { Authenticator } from "@aws-amplify/ui-react";

import i18next from "i18next";
import { BrowserRouter } from "react-router-dom";
import { AwsConfigAuth } from "./config/auth";
import { Amplify } from "aws-amplify";

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import App from "./App";

import app_locale_en from "./translations/en/locale.json";


import "./index.css";

Amplify.configure(AwsConfigAuth);

i18next.init({
    interpolation: { escapeValue: false },
    lng: "en",
    resources: {
        en: {
            locale: app_locale_en
        }
    }
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <Authenticator.Provider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Authenticator.Provider>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
