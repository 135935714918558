export const AwsConfigAuth = {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
        domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
        path: "/",
        expires: 365,
        sameSite: "strict",
        secure: true
    },
    authenticationFlowType: "USER_SRP_AUTH"
};

console.log(AwsConfigAuth);

export const AUTH_TIMEOUT = 100;
export const AUTH_URL_PROTOCOL = process.env.REACT_APP_ENV === "localhost" ? "http" : "https";
