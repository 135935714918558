import React, { useState, useEffect } from 'react';

import { useAuthenticator } from "@aws-amplify/ui-react";

import "./LandingPage.css";

export const LandingPage = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    useEffect(() => {
        if (authStatus === "authenticated") {
            window.location.href = "/home";
        }
    }, [authStatus]);

    return (
        <div>
            <h1>Monitor your workloads, sleep peacefully at night!</h1>
            <div className="fp-content columns-3">
                <div>Do you know how long your workloads take to run? or even if they run at all?</div>
                <div className="centered">
                    <i className="fa-solid fa-arrow-right fa-xl"></i>
                </div>
                <div>
                    <p>Job Bee can help you to answer all of these questions and more!</p>
                    <p>Get alerts when workloads don't run on time, or when they take longer than usual to complete.</p>
                </div>
            </div>
            <div className="fp-content centered">
                <div>
                    <button className="get-started-button">Get Started</button>
                </div>
                <div>
                    Get started on the free tier, no credit card required.
                </div>
            </div>
            <div className="fp-content columns-2">
                <div>Sleep peacefully</div>
                <div>Screenshot goes here</div>
            </div>
        </div>
    );
};